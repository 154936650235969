import css from "styled-jsx/css";

export default css`
.channel-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 79vh;
  border-radius: 8px;
  background-color: #FFFFFF;
  min-width: 66vw;
  padding: 1.5rem;
  .channel-modal_header {
    background-color: #FFFFFF;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid rgb(238, 238, 238);
  }
  .channel-modal_content {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding-left: 1rem;
    overflow: overlay;
    ::-webkit-scrollbar {display: none;}
    .channel-modal_content-title {
      margin-bottom: 1rem;
    }
    .channel-modal_content-channels {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      .card-img {
        width: 5rem;
        height: 5rem;
        padding: 0.8rem;
        border: 1px solid #DDD;
        box-shadow: 0 0 5px 0 #ededed;
        border-radius: 10px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .channel-modal {
    min-width: 100vw;
    padding: 1rem;
    height: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .channel-modal_content {
      gap: 1.5rem;
      .channel-modal_content-channels {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        .card-img {
          width: 3rem;
          height: 3rem;
          padding: 0.2rem;
        }
      }
    }
  }
}
`;
