import React from "react";
import { useDispatch } from "react-redux";
import CustomLink from "@/bit/components/components.custom-link";
import TextInfo from "@/bit/components/components.text";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";
import ChannelModal from "./channel-modal";

const ChannelLink = (props) => {
  const dispatch = useDispatch();

  const handleChannelModal = () => {
    const device = getDeviceKindDependsOnViewPort();
    let modalProps = {
      contentPosition: "center--center",
      opacityLayoutColor: "blur",
      inSceneEffect: "fadeIn",
      outSceneEffect: "fadeOut",
      closeByClickingOutside: true,
      scrollAllowed: false,
      createAnchor: false,
    }
    if (device === "MOBILE") {
      modalProps.contentPosition = "center--bottom"
      modalProps.inSceneEffect = "fadeInBottom"
      modalProps.outSceneEffect = "fadeOutBottom"
    }
    dispatch(
      modalActions.addModal({
        id: "channel-modal",
        Element2Show: ChannelModal,
        modalProps: modalProps,
        elementProps: {
          ...props,
          device
        },
      })
    );
  };
  return (
    <>
      <CustomLink
        onClick={handleChannelModal}
        title="Ver canales"
        taggingInfo={{
          componentVariation: "",
          componentContent: "Ver canales",
          eventType: "click",
        }}
        cyData="ChannelLink CustomLink"
        className="channel-link"
      >
        <TextInfo
          text="Ver canales"
          fontType="font-regular"
          size="caption01"
          color="blue_a"
          display="block"
          cyData="ChannelLink Text"
        />
      </CustomLink>
      <style jsx>
        {`
          :global(.channel-link) {
            margin: 1rem 1.8rem;
          }
        `}
      </style>
    </>
  );
};

export default ChannelLink;
