import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Text from "@/bit/components/components.text";
import Image from "@/bit/components/components.c-image";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import ModalHeader from "@/bit/components/components.header-modal";
import style from "./style";

const ChannelModal = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const modalContent = document.querySelector(".Modal_content");
    modalContent.classList.add("modal-scroll-hidden");
  }, []);

  const handlerCloseModal = () => {
    dispatch(
      modalActions.addModal({
        id: "",
      })
    );
  };
  const channels = Object.values(props["channelsInfo"]);

  const title = {
    color: "grey6",
    size: "title05",
    fontType: "font-bold",
    lineHeight: "regular",
    text: "Canales incluidos",
    cyData: "ChannelLink Modal Title",
  };

  return (
    <div className="channel-modal">
      <div className="channel-modal_header">
        <ModalHeader
          title={title}
          aligned
          taggingInfo={{
            componentName: "ChannelModal",
            componentVariation: "all",
            componentContent: "",
          }}
          onClickClose={handlerCloseModal}
          cyDataTitle="ChannelLink Modal Title"
          cyDataClose="ChannelLink Modal Close"
        />
      </div>
      <div className="channel-modal_content">
        {channels.map((e, index) => {
          return (
            <div
              key={`${e.categorie}-${index}`}
              data-cy={`ChannelLink Modal List-${index}`}
            >
              <div className="channel-modal_content-title">
                <Text
                  text={e.categorie}
                  size="title06"
                  lineHeight="regular"
                  color="grey6"
                  fontType="font-regular"
                  cyData="ChannelLink Modal Categorie"
                />
              </div>
              <div
                className="channel-modal_content-channels"
                data-cy="ChannelLink Modal ImageList"
              >
                {e.imagesChannel.map((item, index) => {
                  const { src, width, height, channelName } = item;
                  return (
                    <div
                      className="card-img"
                      key={`${index}`}
                      title={channelName}
                    >
                      <Image
                        src={src}
                        alt="imagen"
                        width={width}
                        height={height}
                        objectFit="contain"
                        cyData="ChannelLink Modal Image"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <style jsx>{style}</style>
    </div>
  );
};

export default ChannelModal;
