/* eslint-disable prettier/prettier  */
// React
import React from "react";
import PropTypes from "prop-types";
import Text from "@/bit/components/components.text";
import { getColor } from "@gcloud-npm/utils.enums.colors";

const TextList = ({ items }) => {
  const textKeys = Object.keys(items).filter((key) => /^Text-\d+$/.test(key));
  const textValues = textKeys.map((key) => items[key]);
  const colorS = getColor("grey5");  
  if (!textValues || textValues.length==0 || !Array.isArray(textValues)) {
    return null;
  }
  return (
    <>
      <div className="card-text-list" data-cy="Card TextList">
        {textValues.map((item, index) => {
          return (
            <Text
              className="text-list--item"
              key={`item-${index}`}
              {...item}
              cyData="Card TextList Item Text"
            />
          );
        })}
      </div>
      <style jsx>{`
        .card-text-list {
          padding-top:1rem;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
        }
        :global(.text-list--item) {
          display: inline;
          &:not(:last-child)::after {
            display: inline-block;
            margin-bottom: 0.3rem;
            padding: 0px 0.5rem;
            color: ${colorS};
            content: "|";
            vertical-align: top;
          }
        }
      `}</style>
    </>
  );
};

TextList.propTypes = {
  items: PropTypes.object,
};
TextList.defaultProps = {
  items: {},
};

export default TextList;
