import React from 'react';
import Button from '@/bit/components/components.button';
import Text from '@/bit/components/components.text';
import SimplePhone from '@/bit/components/components.simple-phone';

export const SCX_PATH = `https://{env}.cms.col.movistar.es/static/js/scx/scx.js`;

const ActionCallModule = ({
  simplePhone,
  button,
  auxButton = {},
  isButtonFull,
  skeletonClassName,
  textMobileOne,
  textMobileTwo,
}) => {
  const taggingInfo = {
    componentName: 'buy-box',
    componentVariation: 'all',
    componentContent: button && button.label ? button.label : '',
    componentProduct: button.alias || '',
    eventType: 'click',
  };
  const auxTaggingInfo = {
    componentName: 'buy-box',
    componentVariation: 'all',
    componentContent: auxButton && auxButton.label ? auxButton.label : '',
    componentProduct: auxButton.alias || '',
    eventType: 'click',
  };

  return (
    <div
      className={`call-to-action ${
        simplePhone && simplePhone.belowPrice
          ? 'phone-below-price'
          : 'phone-normal'
      }`}
    >
      {textMobileTwo && textMobileTwo?.text && (
        <div className="call-to-action-text-mobile-one">
          <Text {...textMobileTwo} />
        </div>
      )}

      {simplePhone && <SimplePhone {...simplePhone} />}

      {textMobileOne && textMobileOne?.text && (
        <div className="call-to-action-text-mobile-one">
          <Text {...textMobileOne} />
        </div>
      )}

      <div
        className={`buy-box-content-button ${
          isButtonFull
            ? 'buy-box-content-button-full'
            : 'buy-box-content-button'
        }`}
      >
        <div
          className={`skeleton skeleton-buybox skeleton-button ${skeletonClassName}`}
        >
          <div className='call-to-action--button-row'>
          {auxButton && auxButton.label && (
            <Button
            className="aux-button"
            {...auxButton}
            taggingInfo={auxTaggingInfo}
            cyData="BuyBox Button-3"
            />
          )}
          {button && (
            <Button
            className="principal-button"
            {...button}
            taggingInfo={taggingInfo}
            cyData="BuyBox Button-1"
            />
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionCallModule;
