import React from "react";
import TextInfo from "@/bit/components/components.text";
import Price from "@/bit/components/components.price";
import Label from "@/bit/components/components.label";

function PriceModule({
  topLabel,
  prePriceText,
  postPriceText,
  secondaryText,
  mainPrice,
  secondaryPrice,
  skeletonClassName,
}) {
  if (!topLabel && !prePriceText && !mainPrice) return null;

  return (
    <div className="buy-box-content-price" data-cy="BuyBox Price Container">
      {topLabel && (
        <div
          className={`skeleton skeleton-buybox skeleton-label ${skeletonClassName}`}
        >
          <Label {...topLabel} cyData="BuyBox Label-1" />
        </div>
      )}

      {prePriceText && <TextInfo {...prePriceText} cyData="BuyBox Text-0" />}
      <div
        className={`skeleton skeleton-buybox skeleton-price ${skeletonClassName}`}
      >
        {mainPrice && (
          <div className="buy-box-content-price_flex">
            {secondaryPrice && mainPrice?.price !== secondaryPrice?.price && (
              <Price {...secondaryPrice} cyData="BuyBox Price-2" />
            )}
            {mainPrice && <Price {...mainPrice} cyData="BuyBox Price-1" />}
          </div>
        )}
      </div>
      <div
        className={`skeleton skeleton-buybox skeleton-text ${skeletonClassName}`}
      >
        {postPriceText && (
          <TextInfo {...postPriceText} cyData="BuyBox Text-1" />
        )}

        {!secondaryPrice && secondaryText && (
          <TextInfo {...secondaryText} cyData="BuyBox Text-2" />
        )}
      </div>
    </div>
  );
}

export default PriceModule;
