import React from "react";
import TextInfo from "@/bit/components/components.text";
import Price from "@/bit/components/components.price";

const PostCard = (props) => {
  const { skeletonClassName } = props;
  const text = props["Text-7"];
  const price = props["Price-3"];
  
  const { custoObjectInfo } = props;

  let ociText7 = null;
  let ociPrice3 = null;

  if (custoObjectInfo) {
    ociText7 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Text-7",
    });
    ociPrice3 = custoObjectInfo.addEntryPoint({
      custoObjectInfo,
      entryPoint: "Price-3",
    });
  }
  return (
    <div className="capta-price--mobile">
      {/* {text && text.text && (
        <>
          <TextInfo
            className="card-header--title"
            {...text}
            custoObjectInfo={ociText7}
            cyData="Card Text-7"
          />
          {price && (price.amount || price.amount == 0) && (
            <Price
              price={price.amount}
              currency={price.unity}
              quote={price.frequency}
              cyData="Card Price-3"
              custoObjectInfo={ociPrice3}
              {...price}
            />
          )}
          <div className={`${skeletonClassName} postcard`} />
        </>
      )} */}
    </div>
  );
};

export default PostCard;
