import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

/**
 * Componente que representa un botón de cierre utilizado en modales u otros elementos.
 */
const CloseButton = ({
  color,
  backgroundColor,
  onHoverColor,
  onClick,
  cyData,
}) => {
  const StyledCloseButton = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: ${backgroundColor};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: min(40px, 10vw);
    height: min(40px, 10vw);
    transition: background-color 200ms ease;
    z-index: 4;

    &:hover {
      cursor: pointer;
      background-color: ${onHoverColor};

      .cros {
        transform: scale(110%);
      }
    }

    .cros {
      transition: transform 200ms ease;
      position: relative;

      .one,
      .two {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 2px;
        background-color: ${color};
      }
    }
    .one {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    .two {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `;

  return (
    <StyledCloseButton
      data-cy={cyData}
      className="close-container"
      onClick={onClick}
    >
      <div className="cros">
        <span className="one"></span>
        <span className="two"></span>
      </div>
    </StyledCloseButton>
  );
};

CloseButton.propTypes = {
  /**
   * {
   *  "info": "Color de la equis",
   *  "kind": "both",
   *  "beautifulName": "Color"
   * }
   */
  color: PropTypes.string,
  /**
   * {
   *  "info": "Color del fondo",
   *  "kind": "both",
   *  "beautifulName": "BackgroundColor"
   * }
   */
  backgroundColor: PropTypes.string,
  /**
   * {
   *  "info": "Color al asar el ratón por encima del componente",
   *  "kind": "both",
   *  "beautifulName": "OnHoverColor"
   * }
   */
  onHoverColor: PropTypes.string,
  /**
   * {
   *  "info": "Función que realizará al clickar",
   *  "kind": "both",
   *  "beautifulName": "OnClick"
   * }
   */
  onClick: PropTypes.func,
  /**
   * {
   *  "info": "Cadena que identifica inequívocamente el componente, para finalidades de testing",
   *  "kind": "dev",
   *  "beautifulName": "cyData"
   * }
   */
  cyData: PropTypes.string,
};

CloseButton.defaultProps = {
  color: "blue",
  backgroundColor: "transparent",
  onHoverColor: "grey1",
};

export default CloseButton;
