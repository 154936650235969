import React from "react";
import dynamic from "next/dynamic";
import Text from "@/bit/components/components.text";
import Button from "@/bit/components/components.button";

const Icon = dynamic(() => import("@/bit/components/components.icon-glyph"));

/**
 * Alert component
 * @param {string} props.iconType - The type of the icon
 * @param {object} props.textProps - The props for the Text component
 * @returns {React.ReactNode} The rendered Alert component
 */
const Alert = (props) => {
  if (!props) {
    return null;
  }

  const taggingInfo = {
    componentVariation: "Alert",
    componentContent: "Alert",
    eventType: "click",
  };

  return (
    <div className="alert">
      {props["Glyph-1"] && (
        <div className="icon">
          <div className="icon-user">
            <Icon {...props["Glyph-1"]} />
          </div>
        </div>
      )}
      <div className="alert-text">
        {props["Text-1"] && (
          <Text className="modal-legals-device" {...props["Text-1"]} />
        )}
        {props["CustomLink-1"] && (
          <Button {...props["CustomLink-1"]} taggingInfo={taggingInfo} />
        )}
      </div>
    </div>
  );
};

export default Alert;
