import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Imagen from "@/bit/components/components.c-image";
import Label, { LABEL_TYPE } from "@/bit/components/components.label";
import CustomLink from "@/bit/components/components.custom-link";

const ImageSlider = ({ images, labelPromo, name }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isMoving, setIsMoving] = useState(false);
  const sliderRef = useRef(null);

  const handleTouchStart = (event) => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    const currentX = event.touches[0].clientX;
    const differenceX = startX - currentX;

    if (!isMoving && differenceX > 50) {
      // Move to left
      setIsMoving(true);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    } else if (!isMoving && differenceX < -50) {
      // Move to right
      setIsMoving(true);
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    }
  };

  const handleChangeSlide = (index) => {
    if (index > currentIndex) {
      setIsMoving(true);
      setCurrentIndex(index % images.length);
    } else if (index < currentIndex) {
      setIsMoving(true);
      setCurrentIndex((index + images.length) % images.length);
    }
  };

  const handleTransitionEnd = () => {
    setIsMoving(false);
  };

  useEffect(() => {
    if (images) {
      setCurrentIndex(0);
    }
  }, [images]);

  const sliderStyles = {
    transform: `translateX(-${currentIndex * 100}%)`,
    transition: isMoving ? "transform 0.3s ease-in-out" : "none",
  };

  if (
    !images ||
    !Array.isArray(images) ||
    (Array.isArray(images) && images.length < 1)
  ) {
    return null;
  }

  return (
    <section className="section-device-slider" data-cy="Image Slider">
      <div className="column-images" data-cy="Miniature Container">
        {images.map((image, index) => (
          <div
            key={`column-image-${index.toString()}`}
            className="column-images--container"
            data-cy={`Image-Miniature Image-Miniature-${index}  ${
              index === currentIndex ? "Image-Miniature-Active" : ""
            }`}
          >
            <CustomLink
              className="custom-link-image-slider"
              taggingInfo={{
                componentContent: name,
                componentName: "image-slider",
              }}
              onClick={() => handleChangeSlide(index)}
            >
              <div
                className="column-images--container--image-box"
                style={{ position: "relative" }}
              >
                <Imagen
                  src={image}
                  alt={`${name}-${index + 1}`}
                  objectFit="contain"
                  fill
                  priority
                />
              </div>
            </CustomLink>
          </div>
        ))}
      </div>
      <div
        className="device-slider-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <div className="device-slider-container--label">
          {labelPromo && (
            <Label
              className="crazy-label label-images-slider"
              theme={LABEL_TYPE.GIFT}
              label={labelPromo}
              notBorderRadius
            />
          )}
        </div>
        <div
          className={`device-slider ${isMoving ? "moving" : ""}`}
          style={sliderStyles}
          ref={sliderRef}
          onTransitionEnd={handleTransitionEnd}
          data-cy="Slide Container"
        >
          {images.map((image, index) => (
            <div
              className="device-slider--slide"
              data-cy={`Slide-Image Slide-Image-${index} ${
                index === currentIndex ? "Slide-Image-Active" : ""
              }`}
              key={`device-image-slider-${index.toString()}`}
              style={{ position: "relative" }}
            >
              <Imagen
                src={image}
                alt={`${name}-${index + 1}`}
                objectFit="contain"
                fill
                priority
              />
            </div>
          ))}
        </div>
        <div className="device-slider-counter">
          <Label
            theme={LABEL_TYPE.INACTIVE}
            label={`${currentIndex + 1}/${images.length}`}
          />
        </div>
      </div>
    </section>
  );
};

ImageSlider.propTypes = {
  /**
   * Array de imágenes que se van a renderizar en el slider
   */
  images: PropTypes.array.isRequired,
  /**
   * Label que se pinta si el producto tiene una promo
   */
  labelPromo: PropTypes.string,
};

ImageSlider.defaultProps = {
  labelPromo: null,
};

export default ImageSlider;
