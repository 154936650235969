import React from "react";
import TextInfo from "@/bit/components/components.text";
import Button from "@/bit/components/components.button";

const LegalModalLink = ({
  button,
  skeletonClassName,
  text,
  alias,
  onClick,
}) => {
  if (!button && !button?.label && !text) return null;

  const taggingInfoLegalModal = {
    componentName: "buy-box",
    componentVariation: "all",
    componentContent: button && button.label ? button.label : "",
    componentProduct: alias,
    eventType: "click",
  };

  const legalClassNames = text ? "with-pre-legals" : "";

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <hr className="separator" />
      <div
        className={`skeleton skeleton-buybox skeleton-legals ${skeletonClassName} ${
          text && button ? " with-pre-legals" : ""
        } ${text && !button ? " with-pre-legals-no-button" : ""}`}
      >
        <div className={`buy-box-legal ${legalClassNames}`}>
          {text && (
            <TextInfo {...text} className="pre-legals" cyData="BuyBox Text-4" />
          )}

          {button && button.label && (
            <Button
              {...button}
              onClick={onClickHandler}
              className="legal-button"
              taggingInfo={taggingInfoLegalModal}
              cyData="BuyBox Button-2"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LegalModalLink;
