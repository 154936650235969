import React from 'react';
import Text from '@/bit/components/components.text';
import SimplePhone, { C2CModalComponent } from '@/bit/components/components.simple-phone';

export const ActionCallTwoModule = ({ simplePhone, product }) => {
  if (!simplePhone || !product) return null;

  return (
    <div className="action-call-two-cont">
      <hr className="separator" />

      <div className="action-call-two">
        {product['Text-0'] && product['Text-0'].text && (
          <Text {...product['Text-0']} />
        )}
        <div className="action-call-two-content-container">
          {simplePhone && (
            <div className="action-call-two-simplephone">
              <SimplePhone {...simplePhone} />
            </div>
          )}
          {simplePhone?.['C2C-Modal'] && (
            <div className="modal-button">
              <C2CModalComponent {...simplePhone['C2C-Modal']} />
            </div>
          )}
        </div>

        {product['TextMobile-1'] && product['TextMobile-1']?.text && (
          <div className="action-call-two-textMobileOne">
            <Text {...product['TextMobile-1']} />
          </div>
        )}
      </div>
      <style jsx>{`.action-call-two-cont {
  display: none;

  .separator {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .action-call-two {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    .action-call-two-textMobileOne {
      display: block;
    }

    .action-call-two-content-container {
      display: flex;
      justify-content: space-between;
      margin-top: 0.2rem;

      .action-call-two-simplephone {
        width: 100%;
        :global(.simple-phone-component) {
          display: block !important;
        }
      }

      .modal-button {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 869px) {
  .action-call-two-cont {
    // .separator{
    //   margin-top: 10px;
    //   margin-bottom: 10px;
    // }
    .action-call-two {
      .action-call-two-textMobileOne {
        display: none;
      }
      .action-call-two-content-container {
        margin-top: 0.5rem;
        .modal-button {
          display: block;
        }
      }
    }
  }
}
`}</style>
    </div>
  );
};

export default ActionCallTwoModule;
