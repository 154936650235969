/* eslint-disable prettier/prettier */
import React from "react";
import Text from "@/bit/components/components.text";
import getColor from "@gcloud-npm/utils.enums.colors";
import CloseButton from "@/bit/components/components.close-button";
import styled from "styled-components";

const colorBlue = getColor("BLUE_A");
const colorGrey1 = getColor("GREY1");

const HeaderModal = (props) => {
  const { title, onClickClose, taggingInfo, cyDataTitle, cyDataClose } = props;

  const StyledHeaderModal = styled.div`
    background-color: white;
    text-align: left;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-flow: row;

    .close-container {
      position: static;
    }
  `;

  return (
    <>
      <StyledHeaderModal>
        {title && <Text {...title} className="title" cyData={cyDataTitle} />}
        <CloseButton
          onClick={onClickClose}
          color={colorBlue}
          backgroundColor="transparent"
          onHoverColor={colorGrey1}
          taggingInfo={taggingInfo}
          cyData={cyDataClose}
        />
      </StyledHeaderModal>
    </>
  );
};

export default HeaderModal;
