import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Button, { BUTTON_WIDTH } from "@/bit/components/components.button";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";

export const SCX_PATH = `https://{env}.cms.col.movistar.es/static/js/scx/scx.js`;

function StickyButton({ button }) {
  const { logged } = useSelector((state) => state.main);
  const taggingInfo = {
    componentName: "sticky-buy-box",
    componentVariation: "all",
    componentContent: button && button.label ? button.label : "",
    componentProduct: button.alias || "",
    eventType: "click",
  };

  const buttonHref = button?.href
    ? button?.href
    : logged
    ? button.urlLinkclient
    : button.urlLinknoclient;

  useEffect(() => {
    // JavaScript para mostrar el elemento sticky después de hacer scroll en mobile
    const device = getDeviceKindDependsOnViewPort();
    if (device === "MOBILE") {
      window.addEventListener("scroll", function () {
        var elementoSticky = document.getElementById("elementoSticky");
        if (window.scrollY > 550) {
          elementoSticky.style.display = "flex";
        } else {
          elementoSticky.style.display = "none";
        }
      });
    }
  }, []);

  return (
    <>
      {button && buttonHref && (
        <div className="sticky-button" id="elementoSticky">
          <Button
            className="principal-button"
            {...button}
            href={buttonHref}
            taggingInfo={taggingInfo}
            cyData="BuyBox Button-1"
            width={BUTTON_WIDTH.FULL}
          />
        </div>
      )}
      <style jsx>{`
        .sticky-button {
          display: none;
        }
        @media screen and (max-width: 768px) {
          .sticky-button {
            display: none;
            background-color: #fff;
            justify-content: center;
            padding: 1rem 1rem;
            padding-right: 2.5rem;
            width: 100%;
            position: fixed;
            bottom: 0;
            z-index: 30;
          }
        }
      `}</style>
    </>
  );
}

export default StickyButton;
