import React from "react";
import PropTypes from "prop-types";
import MainCharacteristics from "@/bit/components/components.characteristics";
import BuyBox from "@/bit/components/components.buy-box";
// import BuyBox from "@/component/buy-box";
import Card from "@/component/card";
import Text from "@/bit/components/components.text";
import PageSelector from "@/bit/components/components.page-selector";
// import PageSelector from "@/component/page-selector";
import ChannelLink from "@/component/channel-link";
import ImageSlider from "@/component/image-slider";
import StickyButton from "./sticky-button";
import Alert from "@/component/alert";

const ProductBanner = (props) => {
  const {
    productName,
    orionTitle,
    orionBanner,
    alertInfo,
    orionBuyBox,
    orionCharacs,
    orionSelector,
    orionImageSlider,
    simphePhoneFicha,
    alias,
  } = props;
  var showMobile = "";
  if (orionBanner && !orionBanner.showMobile) {
    showMobile = "notShowMobile";
  }

  const thereIsVideo = (orionBanner && orionBanner.mp4 && orionBanner.webm) || false;
  const hasShadow =  (orionBanner && orionBanner.cardShadow && orionBanner.cardShadow);
  const videoMarginTop = (orionBanner && orionBanner.cardShadow && orionBanner.videoMarginTop);
  const thereIsSlider = orionImageSlider;
  const thereIsCard = !thereIsVideo && !thereIsSlider;
  let videoCardCss = `banner-video`;

  if (hasShadow === 'true') {
    videoCardCss = `${videoCardCss} card-shadow`
  }

  return (
    <>
      <div
        className="product-banner container"
        data-cy="Section Product Banner"
      >
        <div
          className={`banner-left-product ${showMobile}`}
          data-cy="Product Banner Left"
        >
          {orionTitle && (
            <div>
              <Text {...orionTitle["Text-1"]} kind="h" level="1"  />
            </div>
          )}
          <div className={thereIsVideo ? videoCardCss: 'banner-cards'}>
            <>
              {thereIsVideo && (
                <video className="video-product-banner" muted autoPlay loop playsInline style={{marginTop: videoMarginTop}}> 
                  <source src={orionBanner.webm.webm} alt={orionBanner.webm.alt} type="video/webm"></source>
                  <source src={orionBanner.mp4.mp4} alt={orionBanner.mp4.alt} type="video/mp4"></source>
                </video>
              )}
              {!thereIsVideo && thereIsSlider && (
                <ImageSlider
                  name={orionImageSlider["Title-1"]}
                  images={orionImageSlider["Images-1"]}
                />
              )}
              {thereIsCard && (
                <Card {...orionBanner} setPriority={true}></Card>
              )}
            </>
          </div>
        </div>
        <div className="banner-right" data-cy="Product Banner Right">
          {orionSelector && Object.keys(orionSelector).length !== 0 && (
            <PageSelector {...orionSelector} />
          )}
          {orionCharacs?.positionTop ? (
            <>
              <MainCharacteristics {...orionCharacs} />
              <br />
              <BuyBox
                {...orionBuyBox}
                productName={productName}
                simplePhone={simphePhoneFicha}
                alias={alias}
              ></BuyBox>
            </>
          ) : (
            <>
              <BuyBox
                {...orionBuyBox}
                productName={productName}
                simplePhone={simphePhoneFicha}
                alias={alias}
              ></BuyBox>
               {alertInfo && (
                <>
                  <br />
                  <Alert {...alertInfo} />
                </>
              )}
              <br />
              <MainCharacteristics {...orionCharacs} />
            </>
          )}
          {orionBuyBox["Product-1"]?.["Channels-1"] && (
            <ChannelLink {...orionBuyBox["Product-1"]["Channels-1"]} />
          )}
        </div>
        {/* Boton sticky pagina tv */}
        {alias === "configura-tv-r" &&
          orionBuyBox["Product-1"] &&
          orionBuyBox["Product-1"]["Button-1"] && (
            <StickyButton
              className="principal-button"
              button={orionBuyBox["Product-1"]["Button-1"]}
              cyData="BuyBox Button-3"
            />
          )}
      </div>

      <style jsx>{`
        .product-banner {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          min-height: 513px;
          padding-top: 2rem;

          :global(.section-device-slider) {
            position: absolute;
            width: 100%;
            height: 100%;
          }
          :global(div:empty) {
            display: none;
          }
          @media screen and (max-width: 768px) {
            min-height: auto;
            padding-top: 0.5rem;
            margin-top: 1.5rem;
          }
          .banner-gap {
            display: none;
          }
          .banner-right {
            width: 100%;
          }
          .banner-left-product {
            display: block;
            height: unset;
            .banner-video{
              display: flex;
              flex-direction: column;
              position: relative;
              width: 100%;
              height: auto;
              overflow:hidden;
              border-radius: 25px;              
              &.card-shadow {
                box-shadow: 0 0 10px #0000001a;
              }
              video{
                width: 105%;
                position: relative;
                left: -1%;
                height: auto;
                max-width: 102%;
              }
            }
            .banner-cards {
              display: flex;
              flex-direction: column;
              aspect-ratio: 4 / 3;
              position: relative;
              width: 100%;
              height: auto;

              &::before {
                content: "";
                width: 100%;
                height: 100%;
                padding-top: 75%;
              }

              :global(.card-column) {
                z-index: -1;
                width: 100%;
                height: 100%;
                position: absolute;
                padding-bottom: 0.5rem;
              }
            }
          }
          .notShowMobile {
            display: none;
            height: 0;

            :global(::before) {
              height: 0;
              display: none;
            }
          }
        }
        @media screen and (min-width: 768px) {
          .product-banner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 2rem;
            .banner-left-product {
              display: block;
              flex: 1;

              .banner-cards {
                :global(.card-container) {
                  justify-content: flex-end;
                  :global(.card-glyph) {
                    width: 50%;
                    margin: 0 auto;
                  }
                  :global(.card-text-list) {
                    :global(.text-list) {
                      text-align: center;
                      margin: 0.5rem 2rem;
                    }
                  }
                }
              }
            }

            .notShowMobile {
              display: block;
              height: auto;
            }
            .banner-gap {
              display: block;
              width: 4rem;
            }
            .banner-right {
              width: 48%;
            }
          }
        }
        @media screen and (min-width: 1024px) {
          .product-banner {
            gap: 4rem;
            .banner-gap {
              width: 8rem;
            }
          }
        }
      `}</style>
    </>
  );
};

ProductBanner.propTypes = {
  layout: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};

ProductBanner.defaultProps = {
  layout: "",
  backgroundColor: "white",
  className: "",
};

export default ProductBanner;
