import React from "react";
import TextInfo from "@/bit/components/components.text";
import Button, { BUTTON_TYPE } from "@/bit/components/components.button";

const PageSelector = (props) => {
  const cardKeys = Object.keys(props).filter((key) => /^Button-\d+$/.test(key));
  const cardValues = cardKeys.map((key) => props[key]);

  return (
    <section className="page-selector">
      {props["Text-1"] && (
        <div className="page-selector--title">
          <TextInfo
            {...props["Text-1"]}
            kind="h"
            level="2"
            cyData="PageSelector Title"
          />
        </div>
      )}
      {cardValues && Array.isArray(cardValues) && cardValues.length > 0 && (
        <div className="page-selector--row">
          {cardValues.map((charac, index) => (
            <div
                className="page-selector--row_button"
                key={`page-selector--${index}`}
              >
                <Button
                  type={
                    charac.selected
                      ? BUTTON_TYPE.PRIMARY_REVERSE
                      : BUTTON_TYPE.COLLAPSED
                  }
                  {...charac}
                  taggingInfo={{
                    componentName: "page-selector",
                    componentVariation: "all",
                    componentContent: charac.label,
                    componentProduct: charac.label,
                    eventType: "click",
                  }}
                  cyData={`PageSelector-${index} Button`}
                />
              </div>
          )
        )}
        </div>
      )}
      <style jsx>{`.page-selector {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  &--title {
    margin-bottom: 0.5rem;
  }

  &--row {
    display: flex;
    align-items: center;
    margin: 0.25rem 0rem;
    &_button {
      margin-right: 0.3rem;
      margin-left: 0.2rem;

      :global(.button-type--collapsed) {
        border: solid 1px #dddddd !important;
      }
    }
  }
}
`}</style>
    </section>
  );
};

PageSelector.propTypes = {};

PageSelector.defaultProps = {};

export default PageSelector;
